// 引入 axios
import axios from "axios";



let base = 'http://localhost:8888';

//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        headers:{'content-type':'application/x-www-form-urlencoded'},
        data: params,
    })
}

